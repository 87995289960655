<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/loudspeakerInfo">喇叭管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
    <el-form-item label="名称" prop="name">
    <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
    </el-form-item>
    <el-form-item label="编号" prop="number">
    <el-input type="text" size="mini" v-model="queryModel.number"></el-input>
    </el-form-item>
    <el-form-item label="所属单位" prop="companyId">
      <el-select-tree
        :props="props"
        :options="companyResult"
        v-model="queryModel.companyId"
        height="200"
      ></el-select-tree>
    </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      stripe
      :height="tableHeight"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="名称" width="200"></el-table-column>
      <el-table-column prop="number" label="编号" width="200"></el-table-column>
      <el-table-column prop="companyName" label="所属单位" width="200"></el-table-column>
      <el-table-column prop="isOnline" label="运行状态" width="80">
        <template slot-scope="{row}">
          <div
            v-if="row.isOnline"
            style="border-radius: 30px;background-color:#67C23A;width:20px;height:20px;"
          ></div>
          <div
            v-if="!row.isOnline"
            style="border-radius: 30px;background-color:#F56C6C;width:20px;height:20px;"
          ></div>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="最后更新时间" width="200"></el-table-column>
      <el-table-column label="操作" width="250" fixed="right">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="sendWechat(row)"
            v-if="row.type == '2' && row.sendWechat == 0"
            >发送消息</el-button
          >
        <el-button size="mini" type="warning" @click="handleEdit(row)"
        >修改</el-button
        >
        <el-button size="mini" type="danger" @click="handleDelete(row)"
        >删除</el-button>
        <el-button size="mini" type="primary" @click="bindDevice(row)">关联设备</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <loudspeakerInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></loudspeakerInfo-detail>
    <loudspeakerDevice-list
      v-if="showDeviceModal"
      :loudspeakerId="loudspeakerId"
      @close="onDetailModalClose2"
    ></loudspeakerDevice-list>
  </div>
</template>
<script>
import loudspeakerInfoDetail from "./loudspeakerInfo-detail";
import loudspeakerDeviceList from "./loudspeakerDevice-list";
import loudspeakerInfoApi from "@/api/base/loudspeakerInfo";
import SelectTree from "@/components/SelectTree";
import companyInfoApi from "@/api/base/companyInfo";
import pageUtil from "@/utils/page";
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "baseLoudspeakerInfoList",
  data() {
    var self = this;

    return {
      queryModel: {
        name: "",
        number: "",
        companyId:"",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      tableHeight: 0,
      showDeviceModal: false,
      treeData: [],
      companyResult: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
    };
  },
  components: {
    "loudspeakerInfo-detail": loudspeakerInfoDetail,
    "loudspeakerDevice-list":loudspeakerDeviceList,
    "el-select-tree": SelectTree,
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("name", self.queryModel.name);
      formData.append("number", self.queryModel.number);

      if(self.queryModel.companyId!=null){
        formData.append("companyId", self.queryModel.companyId);
      }
      else{
        formData.append("companyId", "");
      }

      loudspeakerInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;

          //45为分页栏的高度
          //页面高度-列表上面的高度-分页栏高度
          self.tableHeight = pageUtil.autoAdjustHeight(
            self.$refs.formTable.$el
          );
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          loudspeakerInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        loudspeakerInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    onDetailModalClose2(refreshed) {
      //保存成功后回调
      this.showDeviceModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    bindDevice(record) {
      //关联设备
      this.loudspeakerId = record.id;
      this.showDeviceModal = true;
    },
  },
  mounted: function () {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    this.changePage(1);
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>